






















import { Component, Vue } from "vue-property-decorator";
import { snackController } from "./snack-bar-controller";
@Component
export default class SnackBar extends Vue {
  controller = snackController;
}
